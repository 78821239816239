<template>
    <LoadingView v-if="loading" />
    <article v-if="!loading" class="bg-white shadow sm:rounded">

        <header class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 flex attached-center justify-between gap-8">
            <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700">
                Edit Image
            </h1>
            <nav class="flex flex-none gap-4 justify-end attached-center order-1 lg:order-2">
                <div class="flex-none">
                    <FormButton 
                        class="bg-oakwood-gray-50 hover:opacity-100 transition-opacity delay-75" 
                        icon="fa-cancel" @click="$router.go(-1)">
                        Cancel
                    </FormButton>
                </div>
                <div>
                    <FormButton @click="update" :disabled="!valid" icon="fa-check" class="bg-oakwood-blue-500 text-white">
                        Save
                    </FormButton>
                </div>
            </nav>
        </header>


        <section class="grid grid-cols-1 md:grid-cols-3 p-5 gap-5">
            <h2 class="sr-only">Edit the Image Properties</h2>
            <div class="md:col-span-1">
                <figure class="w-full lg:aspect-none group-hover:opacity-75">
                    <img
                        ref="image"
                        id="theImage"
                        :src="item.url"
                        class="h-full w-full object-cover object-center bg-oakwood-gray-50 min-h-40 mb-4"
                        :alt="item.caption" :title="item.alt" />
                    <figcaption>
                        <dl class="grid grid-cols-4 text-sm text-oakwood-gray-600">
                            <dt class="font-semibold">Created By:</dt>
                            <dd class="col-span-3">
                                <a v-if="item.creator" :href="`mailto:${item.creator?.email}`" class="text-oakwood-blue-600 underline hover:text-oakwood-blue-700">{{ item.creator?.name }}</a>
                                <em v-else>Unknown</em>
                            </dd>
                            <dt class="font-semibold">Date:</dt>
                            <dd class="col-span-3">{{ Intl.DateTimeFormat('en-us',{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', '12hour': true, minute: '2-digit', second: '2-digit'}).format(new Date(item.created_at)) }}</dd>
                            <dt class="font-semibold">Filename:</dt>
                            <dd class="col-span-3">{{ item.filename }}</dd>
                            <dt class="font-semibold">Type:</dt>
                            <dd class="col-span-3">{{ item.type }}</dd>
                            <dt class="font-semibold">Size:</dt>
                            <dd class="col-span-3">{{ numberFromBytes(item.filesize) }}</dd>
                            <dt v-if="item.meta?.dimensions" class="font-semibold">Dimensions:</dt>
                            <dd v-if="item.meta?.dimensions" class="col-span-3">{{ formatToNumber(item.meta.dimensions?.width ?? 0) }} x {{ formatToNumber(item.meta.dimensions?.height ?? 0) }}</dd>
                        </dl>
                    </figcaption>
                </figure>
            </div>
            <div class="md:col-span-2 flex flex-col gap-5">
                <div class="grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label for="title" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A simple identifier for this image">
                        Public Title <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <TextInput placeholder="Like 'Kitchen' or 'Elevation A'" id="title" v-model="item.alt" />
                </div>
                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label for="caption" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="A short description of what the image shows">
                        Public Caption <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                    </label>
                    <textarea id="caption" placeholder="Like 'A view of the kitchen showing the eating area'" v-model="item.caption" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                </div>
                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <label for="keywords" class="block text-sm font-medium leading-6 text-oakwood-gray-900" title="Search values that will help find this image">
                        Keywords
                    </label>
                    <textarea id="keywords" placeholder="Other special search words that are not public-facing" v-model="item.meta.keywords" class="p-4 block w-full rounded-md border-0 py-1.5 text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-300 placeholder:text-oakwood-gray-400 focus:ring-2 focus:ring-inset focus:ring-oakwood-blue-600 sm:text-sm sm:leading-6"></textarea>
                </div>

                <div class="border-t border-t-oakwood-gray-500 grid grid-cols-1 md:grid-cols-2 pt-5">
                    <div class="grid gap-2">
                        <label for="category" class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                            Category <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                        </label>
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="category" v-model="item.meta.category"
                                class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                <option :value="null">Please Select...</option>
                                <option v-for="category, k in categories" :key="`category-${k}`" :value="category.value">{{
                                    category.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="grid gap-2" v-if="item.meta.category == 'home'">
                        <label for="position" class="block text-sm font-medium leading-5 text-oakwood-gray-700 sm:mt-px sm:pt-2">
                            View <span class="text-oakwood-red cursor-help" title="This field is required">*</span>
                        </label>
                        <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
                            <select id="position" v-model="item.meta.position"
                                class="block p-2 bg-white border border-oakwood-gray-300 rounded w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                                <option :value="null">Please Select...</option>
                                <option v-for="position, k in positions" :key="`position-${k}`" :value="position">{{
                                    position }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section v-if="id" class="px-4 py-5 border-b border-oakwood-gray-200 sm:px-6 border-t border-t-oakwood-gray-200 flex flex-col">
            <h2 class="text-xl text-oakwood-blue-600 font-medium">Attached Locations</h2>
            <template v-for="pane, i in panes" :key="`pane-${i}`">
                <div class="flex justify-between items-center mt-8">
                    <h3 class="text-lg text-oakwood-red-600 font-medium">{{ pane.label }}</h3>
                    <div>
                        <FormButton @click="openAttach(pane)" icon="fa-plus-circle" class="bg-oakwood-blue-600 hover:bg-oakwood-blue-700 text-white">
                            Bulk Attach
                        </FormButton>
                    </div>
                </div>
                <PaginationTable :data="filtered[pane.name]" :limit="5">
                    <template #headers>
                        <th scope="col"
                            class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th v-if="['floorplan','sales_order'].includes(pane.name)" scope="col"
                            class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                            Collection
                        </th>
                        <th scope="col" class="w-1/3 px-6 py-3 bg-oakwood-gray-50 text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider text-right">
                            <span class="sr-only">Actions</span>
                        </th>
                    </template>
                    <template v-if="pane.name === 'floorplan'" #columns="{row, i:k}">
                        <td class="whitespace-no-wrap text-sm">
                            <router-link :to="{name: 'floorplan-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                                {{ row.model?.meta?.info?.name }}
                            </router-link>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm">
                            {{ row?.model?.collection?.meta?.info?.name }}
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                            <FormButton @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                                <span class="sr-only">Detach</span>
                            </FormButton>
                        </td>
                    </template>

                    <template v-else-if="pane.name === 'sales_order'" #columns="{row, i:k}">
                        <td class="whitespace-no-wrap text-sm">
                            <router-link :to="{name: 'available-homes-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                                {{ row.model?.kova?.Lot?.StreetAddress }}, {{ row.model?.kova?.Lot?.City }}, {{ row.model?.kova?.Lot?.State }} {{ row.model?.kova?.Lot?.ZipCode }}
                            </router-link>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm">
                            {{ row?.model?.collection?.meta?.info?.name }}
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                            <FormButton @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                                <span class="sr-only">Detach</span>
                            </FormButton>
                        </td>
                    </template>

                    <template v-else-if="pane.name === 'collection'" #columns="{row, i:k}">
                        <td class="whitespace-no-wrap text-sm">
                            <router-link :to="{name: 'collection-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                                {{ row.model.meta?.info?.name }}
                            </router-link>   
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                            <FormButton @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                                <span class="sr-only">Detach</span>
                            </FormButton>
                        </td>
                    </template>

                    <template v-else-if="pane.name === 'division'" #columns="{row, i:k}">
                        <td class="whitespace-no-wrap text-sm">
                            <router-link :to="{name: 'community-tab', params: {id: row.model.id, tab: 'photos'}}" class="text-oakwood-blue-600 px-6 py-4 block">
                                {{ row.model.meta?.info?.name }}
                            </router-link> 
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex gap-2 justify-end">
                            <FormButton @click="unsync(k, pane.name)" title="Detach the image from this item" icon="fa-unlink" class="bg-oakwood-red-600 hover:bg-oakwood-red-700 text-white">
                                <span class="sr-only">Detach</span>
                            </FormButton>
                        </td>
                    </template>
                </PaginationTable>
            </template> 
        </section>
        
    </article>

    <ModalDialog v-if="context" :open="context !== null" @close="cancel">
        <template #header>
            <h2>Bulk Attach {{ context.label }}</h2>
        </template>
        <template #body>
            <div class="flex items-center gap-4">
                <div v-if="['floorplan','sales_order'].includes(context.name)">
                    <SelectField v-model="query.collection">
                        <option :value="null">All Collections</option>
                        <option v-for="collection, i in collections" :key="`collection-${i}`" :value="collection.community_rid">{{ collection.meta?.info?.name }}</option>
                    </SelectField>
                </div>
                <div>
                    <TextInput v-model="query.search" placeholder="Search by keyword" />
                </div>
            </div>
            <PaginationTable :data="filteredItems" :limit="5">
                <template #headers>
                    <th scope="col"
                    class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        <!-- <label for="select_all">Select All</label>
                        <input id="select_all" type="checkbox" v-model="all" :value="all || true"> -->
                    </th>
                    <th scope="col"
                        class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        Name
                    </th>
                    <th v-if="['floorplan','sales_order'].includes(context.name)" scope="col"
                        class="px-6 py-3 bg-oakwood-gray-50 text-left text-xs leading-4 font-medium text-oakwood-gray-500 uppercase tracking-wider">
                        Collection
                    </th>
                </template>
                <template #columns="{row}">
                    <th scope="row" class="px-6 py-3">
                        <input type="checkbox" v-model="selected" :value="row.id">
                    </th>
                    <td class="px-6 py-3">
                        {{ context.name == 'sales_order' ? `${row.kova.Lot.StreetAddress}` : row.meta?.info?.name || row.kova.Name }}
                    </td>
                    <td class="px-6 py-3" v-if="['floorplan','sales_order'].includes(context.name)" scope="col">
                        {{ getCollection(row.kova?.CommunityRID)?.meta?.info?.name }}
                    </td>
                </template>
            </PaginationTable>
        </template>
        <template #buttons>
            <FormButton @click="cancel" icon="fa-cancel" class="bg-oakwood-gray-200">Cancel</FormButton>
            <FormButton @click="sync" :disabled="!selected.length" icon="fa-link" class="bg-oakwood-blue-500 hover:bg-oakwood-blue-600 text-white">Attach to Selected</FormButton>
        </template>
    </ModalDialog>
</template>

<script>
import axios from 'axios';
import LoadingView from '@/components/LoadingView.vue';
import { FormButton, SelectField, TextInput } from '@/components/form';
import Media from '@/models/media';
import PaginationTable from '@/components/PaginationTable.vue';
import ModalDialog from '@/components/ModalDialog.vue';
import { handle404 } from '@/helpers/globals';
import { numberFromBytes, formatToNumber } from '@/helpers/numberHelpers';

const query = {
    search: '',
    collection: null
}

export default {
    head() {
        return {
            title: () => `${this.$route.meta.title} %separator ${this.item?.alt}`
        }
    },
    setup() {
        return {
            numberFromBytes,
            formatToNumber
        }
    },
    data() {
        return {
            id: null,
            item: {...(new Media)},
            categories: Media.categories,
            loading: true,
            positions: Media.positions,
            panes: [
                {
                    name: 'floorplan',
                    endpoint: 'floorplans',
                    label: 'Floorplans',
                    category: 'home'
                },
                {
                    name: 'sales_order',
                    endpoint: 'sales_orders',
                    label: 'Available Homes',
                    category: 'home'
                },
                {
                    name: 'collection',
                    endpoint: 'collections',
                    label: 'Collections',
                    category: 'community'
                },
                {
                    name: 'division',
                    endpoint: 'divisions/communities',
                    label: 'Communities',
                    category: 'community'
                }
            ],
            attached: {
                floorplan: [],
                sales_order: [],
                collection: [],
                division: []
            },
            collections: [],
            items: [],
            query: {...query},
            context: null,
            selected: []
        }
    },
    methods: {
        show() {
            if(!this.id) return;
            axios
                .get(`media/${this.id}`)
                .then(res => {
                    this.item = res.data;
                    if(res.data.meta === null)
                        this.item.meta = {
                            ...(new Media).meta
                        }
                    else {
                        res.data.meta = {
                            ...(new Media).meta,
                            ...res.data.meta
                        }
                    }
                    this.loading = false;
                    this.index();
                    this.indexCollections();
                })
                .catch(err => {
                    if(err.response.status === 404)
                        handle404(this, err.response.data.message);
                });
        },
        index() {
            axios.get(`media/${this.id}/attachments`)
            .then(resp => {
                this.attached = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
        },
        openAttach(context) {
            this.context = context;
            this.indexItems();
        },
        indexCollections() {
            axios.get('collections')
            .then(resp => {
                this.collections = resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
            })
            .catch(err => {
                console.error(err);
            });
        },
        indexItems() {
            if(!this.context) return;
            let endpoint = this.context.endpoint;
            switch(endpoint) {
                case 'collections' :
                    this.items = this.collections;
                    break;
                default:
                    axios.get(endpoint)
                    .then(resp => {
                        this.items = endpoint === 'sales_orders' ? 
                        resp.data.sort((a,b) => a.kova.Lot.StreetAddress > b.kova.Lot.StreetAddress ? 1 : -1) :
                        resp.data.sort((a,b) => a.meta?.info?.name > b.meta?.info?.name ? 1 : -1);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }    
        },
        cancel() {
            this.context = null;
            this.items = [];
            this.selected = [];
            this.query = {...query};
        },
        update() {
            axios
                .patch(`media/${this.id}`, this.item)
                .then(() => {
                    this.$router.go(-1);
                })
                .catch(err => {
                    console.error(err);
                })
        },
        sync() {
            axios.post(`media/${this.item.id}/attachments`, {items: this.selected, type: this.context.name})
            .then(resp => {
                this.attached[this.context.name] = resp.data;
                this.cancel();
            })
            .catch(err => {
                console.error(err);
            })
        },
        unsync(index, context) {
            let item = this.attached[context][index];
            axios.delete(`attachments/${item.id}`)
            .then(resp => {
                this.attached[context].splice(index, 1);
            })
            .catch(err => {
                console.error(err);
            })
        },
        getCollection(rid) {
            return this.collections.find(collection => collection.community_rid === rid) || '-';
        }
    },
    computed: {
        valid() {
            if(this.item.alt == '' || this.item.caption == '') return false;
            if(this.item.meta.position === null) return false;
            return true;
        },
        filtered() {
            return this.attached;
        },
        filteredItems() {
            let ids = this.attached[this.context.name].map(attach => attach.id);
            let items = this.items.filter(item => !ids.includes(item.id) && item.status !== 'inactive');

            if(this.query.search) {
                items = items.filter(item => JSON.stringify(item).toLowerCase().includes(this.query.search.toLowerCase()));
            }

            if(this.query.collection) {
                items = items.filter(item => item.kova.CommunityRID == this.query.collection);
            }

            return items;
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.show();
    },
    watch: {
        'item.meta.category' : {
            handler(newVal, oldVal) {
                if(newVal == oldVal) return;

                switch(newVal) {
                    case 'home' :
                        this.item.meta.position = null;
                        break;
                    case 'community' :
                        this.item.meta.position = 'Standard';
                        break
                }
            }
        }
    },
    components: {
        LoadingView, FormButton, PaginationTable, ModalDialog, SelectField, TextInput
    }
}
</script>