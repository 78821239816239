<template>
    <LoadingView v-if="isLoading" />
    <h1 class="text-2xl leading-6 font-medium text-oakwood-blue-700 mb-8">Communities</h1>
    <div class="flex flex-col">
      <div class="inline-flex flex-col flex-wrap md:flex-row md:justify-start gap-4">
        <div class="mb-4 flex-initial">
          <label for="search" class="block text-sm font-medium leading-5 text-oakwood-gray-700">Search by Keyword</label>
          <input v-model="query.search" placeholder="Search communities by keyword" id="search" name="search"
            type="text" autocomplete="off"
            class="p-4 mt-1 sm:w-72 focus:ring-oakwood-blue-500 focus:border-oakwood-blue-500 block w-full shadow-sm sm:text-sm border-oakwood-gray-300 rounded-md h-8">
        </div>
        <div class="mb-4 flex-initial">
          <SelectMultipleField v-model="query.status" label="Published" :options="statuses" empty="All Statuses"/>
        </div>
        <div v-if="hasFilters" class="mb-4 flex-initial sm:mt-5">
          <FormButton @click="clear" class="bg-oakwood-blue-500 text-white">Clear Filters</FormButton>
        </div>
      </div>

      <PaginationTable v-if="!isLoading" :data="filtered">
        <template #headers>
          <th
            class="px-6 py-3 ">
            Name
          </th>
          <th
            class="px-6 py-3">
            Sales Status
          </th>
          <th
            class="px-6 py-3">
            Published
          </th>
        </template>
        <template #columns="{row}">
          <td class="col-span-3 order-1 block sm:table-cell">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'community-show', params: { id: row.id } }">
              <div class="flex items-center">
                <div class="flex-shrink-0 rounded-full overflow-hidden bg-oakwood-blue-400 h-10 w-10 flex items-center justify-center text-white">
                  <img v-if="row.image" class="aspect-square object-cover"
                    :src="row.image" alt="">
                  <i v-else class="fas fa-signs-post text-xl"></i>
                </div>
                <div class="ml-4">
                  <div class="text-sm leading-5 font-medium text-oakwood-gray-900">
                    {{ row.meta?.info?.name || row.kova?.WebGroupName }}
                    <span v-if="row?.status == 'new'" class="bg-oakwood-gray-50 text-oakwood-blue-800 px-2 rounded-full inline-block text-xs leading-5 ml-1">New</span>
                  </div>
                </div>
              </div>
            </router-link>
          </td>
          <td class="col-span-3 order-1 block sm:table-cell text-sm leading-5 font-medium text-oakwood-gray-900">
            <router-link class="block px-6 py-4 whitespace-no-wrap" :to="{ name: 'community-show', params: { id: row.id } }">
              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" 
                :class="['Closed'].includes(row.kova?.Status) ? 'bg-oakwood-red-100 text-oakwood-red-800' : 'bg-oakwood-green-100 text-oakwood-green-800' ">
                {{ row.kova?.Status }}
              </span>
            </router-link>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-oakwood-blue-700 block order-1 sm:table-cell">
            <div class="flex items-center gap-2">
              <ToggleButton v-if="user.level >= 2" :id="`item-${row.id}-status`" @changed="save(row)" :checked="row.status == 'active'" 
                :label="(row.status == 'active' && 'Published') || (row.status == 'unpublished' && 'Unpublished') || row.status == 'new' && 'New'"/>
              <template v-else>
                <p title="Published" class="text-oakwood-blue fas fa-square-check" v-if="row.status == 'active'"></p>
                <p title="Unpublished" class="text-oakwood-gray-400 fas fa-square-x" v-else></p>
              </template>
            </div>
          </td>
        </template>
      </PaginationTable>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import LoadingView from '@/components/LoadingView.vue';
  import {ToggleButton,SelectMultipleField,FormButton} from '@/components/form';
  import { mapWritableState, mapState, mapActions } from 'pinia';
  import { useCommunityStore } from "@/stores/communities";
import PaginationTable from "@/components/PaginationTable.vue";
import { safe } from "@/helpers/regex";
import { useAuthStore } from "@/stores/auth";
  
  export default {
    data() {
      return {
        communities: [],
        isLoading: true,
        statuses: [
          {
            label : 'Published',
            value : 'active'
          },
          {
            label : 'Unpublished',
            value : 'inactive'
          },
          {
            label : 'New',
            value : 'new'
          }
        ]
      }
    },
    methods: {
      ...mapActions(useCommunityStore, ['setPage','clear']),
      index() {
        axios.get(`divisions/communities`)
          .then(res => {
            this.communities = res.data;
            this.isLoading = false;
          })
          .catch(e => {
            throw e;
          });
      },
      save(item) {
        item.status = item.status != 'active' ? 'active' : 'inactive';
        axios.patch(`divisions/${item.id}`, item)
        .then(res => {
          if(res.data?.id) {
            this.index();
          }
        })
        .catch(e => {
          throw e;
        });
      }
    },
    computed: {
      ...mapState(useAuthStore, ['user']),
      ...mapState(useCommunityStore, ['hasFilters']),
      ...mapWritableState(useCommunityStore, ['query', 'page']),
      filtered() {
        let results = this.communities;
        if (!this.query.status.includes(null)) {
          results = results.filter(item => this.query.status.includes(item.status));
        }
          
        if (this.query.search != '') {
          const regex = new RegExp(safe(this.query.search), 'i');
          results = results.filter(item => regex.test(JSON.stringify(item)));

        } 
        return results;
      },
    },
    created() {
      this.index();
    },
    components: { LoadingView, ToggleButton, SelectMultipleField, FormButton, PaginationTable },
  }
  </script>