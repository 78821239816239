<template>
    <label v-if="!$attrs.id" :for="getId" :class="{'sr-only' : !showLabel}" class="block text-sm font-medium leading-5 text-oakwood-gray-700">{{ label }}</label>
    <p class="bg-white sm:text-sm sm:leading-6 w-full rounded-md inline-flex items-stretch text-oakwood-gray-900 shadow-sm ring-1 ring-inset ring-oakwood-gray-200 focus-within:ring-3 focus-within:ring-inset focus-within:ring-oakwood-blue-600">
        <input :id="getId" class="bg-transparent rounded-md p-4 py-1.5 w-full placeholder:text-oakwood-gray-400" v-bind="$attrs" type="text" v-model="value" />
    </p>
</template>

<script>
export default {
    emits: [
        'update:modelValue'
    ],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        getId() {
            return this.$attrs.id || `field-${this.$.uid}`;
        }
    },
    props: {
        modelValue: {
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
        }
    }
}
</script>
